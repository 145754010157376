import React from "react";
import { Converter } from "showdown";
import { StylesManager, Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { renderToStaticMarkup } from "react-dom/server"

import "survey-core/defaultV2.css";
import "./index.css";
import { json } from "./json.js"
import logo from "./imgs/logo.png";
import styles from "./styles.module.css";

StylesManager.applyTheme("defaultV2");

function SurveyComponent() {
    const survey = new Model(json);

    survey.goNextPageAutomatic = true;
    var converter = new Converter();

    survey.onTextMarkdown.add(function(survey, options) {
        //convert the markdown text to html
        var str = converter.makeHtml(options.text);
        //remove root paragraphs <p></p>
        str = str.substring(3);
        str = str.substring(0, str.length - 4);
        //set html
        options.html = str;
    });

    survey.onComplete.add(function () {
        survey.stopTimer()
        sessionStorage.setItem('aceScore', survey.getCorrectAnswerCount())
        sessionStorage.setItem('timeSpent', survey.timeSpent)
        document.location = "/audio";
    });


    survey.onAfterRenderSurvey.add(function(survey, options) {
        var header =
            <div id={'tqi_general_header'}>
                <div id={'header_logo'}>
                    <a href={"https://www.tqintelligence.com"}>
                        <img src={logo} id={'tqi_image_logo'}>
                        </img>
                    </a>
                </div>
                <div id={'header_menu'} className={'header_menu_flex_container'}>
                    <a href={"https://www.tqintelligence.com"}>Home</a>
                    <a href={"https://www.tqintelligence.com/clarity-ai/"}>Clarity AI</a>
                    <a href={"audio"}>Voice Demo</a>
                </div>
            </div>

        var sv_custom_header = document.getElementsByClassName('sv_custom_header')[0];
        sv_custom_header.innerHTML = renderToStaticMarkup(header)
        survey.startTimer();
    })


   return (<Survey model={survey}  className="survey"/>);
}

export default SurveyComponent;