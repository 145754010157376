

import React from 'react'

import  Recorder  from './Recorder.js'
import './Audio.css'
import { Col, Divider, Row, Layout, Typography } from 'antd';
// import Spinner from 'react-bootstrap/Spinner';
import 'antd/dist/antd.css';
import logo from "./imgs/logo.png";  
import {Spinner} from "reactstrap"

const { Header, Footer, Sider, Content } = Layout;
const { Paragraph } = Typography; 
 
class Audio extends React.Component {
  
 

  constructor(props) {
    super(props);
    this.state = {
      audioDetails: {
        url: null,
        blob: null,
        chunks: null,
        duration: {
          h: 0,
          m: 0,
          s: 0
        }
      },
      emotionPredictions: {
        anger: null,
        fear: null,
        sadness: null
      },
      isResultVisible: false,
      IsLoading : false
    };
  }
   


  handleAudioStop(data) {
    console.log(data)
    
  }


  handleAudioUpload(file) {
    this.setState({IsLoading : true});
    console.log(file); 
    var predUrl = "https://fast-api-gateway-ck966wer.ue.gateway.dev/fastAPI?"   
    var xhr = new XMLHttpRequest();
    var fileReader = new FileReader();
    var emotion_levels = ['low', 'medium', 'high']
    fileReader.readAsDataURL(file);
    fileReader.onloadend = function () {
      var webmDataBase64 = fileReader.result.split(',')[1];
      var data = {"instances": [{"body": {"b64": webmDataBase64}}]} 
      
      xhr.open("POST", predUrl)
      xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
      xhr.setRequestHeader('Access-Control-Allow-Headers', '*'); 
      xhr.setRequestHeader('acccept', 'application/json')
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8')
      //xhr.setRequestHeader("Authorization", authKey)     
      xhr.send(JSON.stringify(data))
    
     
      sessionStorage.setItem('anger', emotion_levels[Math.floor(Math.random() * 3)])
      sessionStorage.setItem('fear', emotion_levels[Math.floor(Math.random() * 3)])
      sessionStorage.setItem('sadness', emotion_levels[Math.floor(Math.random() * 3)])
    };

      xhr.onload = (e) => {
        console.log(xhr.responseText);
        var myObj = JSON.parse(xhr.responseText);
        console.log(myObj);

        this.setState({
          emotionPredictions: myObj.predictions[0].aggregate, 
        });
        this.setState({IsLoading : false});
        console.log('desmond caulley');
        document.location = "/results";
    
    }
  }



  handleReset() {
    const reset = {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: 0,
        m: 0,
        s: 0
      }
    };
    this.setState({ audioDetails: reset });
  }




  render() {


    const sadness = "high";
    const anger = "medium";
    const fear = "low";



    /*
    const sadness = this.state.emotionPredictions.sadness;
    const anger = this.state.emotionPredictions.anger;
    const fear = this.state.emotionPredictions.fear;
    */

 return(

        <box>
          <div id={'tqi_general_header'}>
            <div id={'header_logo'}>
                <a href={"https://www.tqintelligence.com"}>
                    <img src={logo} id={'tqi_image_logo'}>
                    </img>
                </a>
            </div>
            <div id={'header_menu'} className={'header_menu_flex_container'}>
                <a href={"https://www.tqintelligence.com"}>Home</a>
                <a href={"https://www.tqintelligence.com/clarity-ai/"}>Clarity AI</a>
                <a href={"/"}>ACE Survey</a>
            </div>
         </div>
          <div id={'audio_recorder_instructions'} >
              <ul id={'recorder_instructions_list'}>
                <li>
                  1. Press the mic button
                </li>
                <li >
                  2. Read the text below
                </li>
                <li>
                  3. Get your emotional state predictions
                </li>
              </ul>
          </div>
          <div id={'recorder_text_heading'}>
            <p>
              Text
            </p>
          </div>
          <div id={'recorder_text_to_read'}>
            <p>
              "It’s no use going back to yesterday, because I was a different person then."<br></br>

              "But I don’t want to go among mad people," Alice remarked. <br></br>
              "Oh, you can’t help that," said the Cat: "we’re all mad here. I’m mad. You’re mad." <br></br>
              "How do you know I’m mad?" said Alice. <br></br>
              "You must be," said the Cat, "or you wouldn’t have come here."<br></br>

              "She generally gave herself very good advice, (though she very seldom followed it)."
            </p>
          </div>
          {this.state.IsLoading === false ? ( <Recorder
        record={true}
        title={"New recording"}
        audioURL={this.state.audioDetails.url}
        showUIAudio
        handleAudioStop={data => this.handleAudioStop(data)}
        handleAudioUpload={data => this.handleAudioUpload(data)}
        handleReset={() => this.handleReset()}
        mimeTypeToUseWhenRecording={'audio/webm;codecs=opus'}
        />):(<div className='audio-loader' >
          <Spinner color='black'  > </Spinner>
        </div>)}
       
      </box>  
    )
  }
    
  }


export default Audio