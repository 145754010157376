import React from "react";
import {Col, Divider, Row} from "antd";
import 'antd/dist/antd.css';
export default function Disclaimer() {
    return (
        <body>
        <h1>Your Consent</h1>
        <p> Before we go ahead here are some things you should know.</p>
        <p>
            You will not be asked for any information that will be able to identify you,
            and you should take care not to mention the names of people, companies,
            locations or events in the answers that you provide.
            Your voice recordings will not be retained once they have been used to produce your stress measurements.
        </p>
        <label className="container">
            <input type="checkbox" />
                <span className="checkmark"></span>
            By ticking the box, you provide you consent
        </label>
        <div className="container p-3 my-3 border">
        <h1>My First Bootstrap Page</h1>
        <p>This container has a border and some extra padding and margins.</p>
        </div>

        <Divider orientation="left">Normal</Divider>
      <Row>
        <Col span={6} >
          anger
        </Col>
        <Col span={6} order={3}>
          fear
        </Col>
        <Col span={6} order={2}>
         sadness
        </Col>
      </Row>
        <Row>
        <Col span={6} order={4}  style={{background: "yellow"}}>
          low
        </Col>
        <Col span={6} order={3} style={{background: "yellow"}}>
          medium
        </Col>
        <Col span={6} order={2} style={{background: "yellow"}}>
         high
        </Col>
      </Row>

        </body>



    )
}