import React from 'react'

import SurveyComponent from "./SurveyComponent";
import './results_page.css'
import logo from "./imgs/logo.png";
import bcbs from "./imgs/bluecrossblueshield_logo.png"
import tqi_logo from "./imgs/tqintelligence_logo.jpeg"


class ResultsPage extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidUpdate() {
        this.setSeverityColorsOnDiv();
    }

    getACESeverity() {
        if (sessionStorage.getItem('aceScore') <  4) {
            return 'Low'
        }
        else {
            return 'High'
        }
    }

    setSeverityColorsOnDiv(emotion) {
        var severity_colors = {'low': 'rgba(0, 191, 255, 0.4)', 'medium': 'rgba(252,133,16,0.7)', 'high': 'red'}
        return severity_colors[sessionStorage.getItem(emotion)]
    }

    getDateString() {
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August",
            "September", "October", "November", "December"];
        var dateObj = new Date();
        var month = dateObj.getUTCMonth()
        var day = dateObj.getUTCDate();
        var year = dateObj.getUTCFullYear();

        var formated_date = monthNames[month] + " " + day + ", " + year;

        return formated_date
    }

    getTimeSpentOnSurvey() {
        var timeSpent = sessionStorage.getItem('timeSpent')
        var minutes = Math.floor(timeSpent / 60);
        var seconds = timeSpent - minutes * 60;

        if (minutes == 0) {
            return seconds + " sec"
        }
        else {
            return minutes + "min " + seconds + "sec"
        }
    }

    render() {
        return (
            <div id={'results_body_div'}>
                <div id={'tqi_general_header'}>
                    <div id={'header_logo'}>
                        <a href={"https://www.tqintelligence.com"}>
                            <img src={logo} id={'tqi_image_logo'}>
                            </img>
                        </a>
                    </div>
                    <div id={'header_menu'} className={'header_menu_flex_container'}>
                        <a href={"https://www.tqintelligence.com"}>Home</a>
                        <a href={"https://www.tqintelligence.com/clarity-ai/"}>Clarity AI</a>
                        <a href={"/"}>ACE Survey</a>
                    </div>
                </div>
                <div id={'ace_score_main_section'}>
                    <div id={'ace_score_main_section_left'}>
                        <div id={'ace_disclaimer_section'}>
                            <span>
                                Patients with an ACE score of 4 or above have significant trauma symptoms. Learn more at &nbsp;
                                <a id={'ace_disclaimer_bcbs'} href={'https://www.bluecrossma.org/myblue/your-health/mental-and-behavioral-health/mental-health-resource-center'}>
                                    <u>Blue Cross Blue Shield</u>
                                </a>
                            </span>
                        </div>
                        <div id={'ace_big_text_section'}>
                            <p id={'ace_score_write_out'}> ACE Score </p>
                            <p id={'ace_score_explanation'}> {sessionStorage.getItem('aceScore')} adverse childhood experiences noted</p>
                        </div>
                        <div id={'ace_score_in_numbers'}>
                            <p>
                                <span id={'ace_number_large'}>{sessionStorage.getItem('aceScore')}
                                </span> of 10
                            </p>
                        </div>
                        <div  id={'ace_progress_bar_div'}>
                            <div id={'ace_progress_bar_background'}>
                                <div id={'ace_progress_bar_ranking'} style={{height:'20px' , width:`${sessionStorage.getItem('aceScore')*10}%`}}>
                                </div>
                            </div>
                        </div>
                        <div className="progress">
                            <div className="progress-bar" role="progressbar" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                    <div id={'ace_vertical_divider'}></div>
                    <div id={'ace_metadata_info'}>
                        <p> <span id={'ace_date_meta_main'}>Date:</span> {this.getDateString()} </p> <br></br>
                        <p> Time Spent on Survey: <span className={'format_meta_response'}>{this.getTimeSpentOnSurvey()} </span></p>
                        <p> Severity Level: <span className={'format_meta_response'}>{this.getACESeverity()} </span></p>
                        <p> Number of adverse experiences: <span className={'format_meta_response'}>{sessionStorage.getItem('aceScore')} </span></p>
                        <p> Total Number of Questions: <span className={'format_meta_response'}>{10} </span></p>
                    </div>
                </div>
                <div id={'severity_level_section'}>
                    <div id={'fear_section_div'} style={{backgroundColor: this.setSeverityColorsOnDiv('fear')}}>
                        <p className={'emotion_heading emotion_section_text_adjust'}> Fear Severity </p>
                        <p className={'emotion_severity_label emotion_section_text_adjust'}> {sessionStorage.getItem('fear').toUpperCase()} </p>
                        <p className={'emotion_severity_label emotion_section_text_adjust emotion_suggestion_and_tqi'}>
                          {
                              sessionStorage.getItem('fear').toUpperCase() === 'HIGH' ?
                                  <span id={'suggestion_text'}> Suggestion: &nbsp;
                                      <span id={'bcbs_hyperlink'}>
                                          <a href={'https://www.bluecrossma.org/myblue/your-health/mental-and-behavioral-health/mental-health-resource-center'}>
                                              Click to Visit BCBS
                                          </a>
                                      </span>
                                  </span> :
                                  <span id={'powered_by_tqi_msg'}>powered by TQIntelligence VoiceAI</span>
                          }
                        </p>
                    </div>
                    <div id={'anger_section_div'} style={{backgroundColor: this.setSeverityColorsOnDiv('anger')}}>
                        <p className={'emotion_heading emotion_section_text_adjust'}> Anger Severity </p>
                        <p className={'emotion_severity_label emotion_section_text_adjust'}> {sessionStorage.getItem('anger').toUpperCase()}  </p>
                        <p className={'emotion_severity_label emotion_section_text_adjust emotion_suggestion_and_tqi'}>
                          {
                              sessionStorage.getItem('anger').toUpperCase() === 'HIGH' ?
                                  <span id={'suggestion_text'}> Suggestion: &nbsp;
                                      <span id={'bcbs_hyperlink'}>
                                          <a href={'https://www.bluecrossma.org/myblue/your-health/mental-and-behavioral-health/mental-health-resource-center'}>
                                              Click to Visit BCBS
                                          </a>
                                      </span>
                                  </span> :
                                  <span id={'powered_by_tqi_msg'}>powered by TQIntelligence VoiceAI</span>
                          }
                        </p>
                    </div>
                    <div id={'sadness_section_div'} style={{backgroundColor: this.setSeverityColorsOnDiv('sadness')}}>
                        <p className={'emotion_heading emotion_section_text_adjust'}> Sadness Severity </p>
                        <p className={'emotion_severity_label emotion_section_text_adjust'}> {sessionStorage.getItem('sadness').toUpperCase()}  </p>
                        <p className={'emotion_severity_label emotion_section_text_adjust emotion_suggestion_and_tqi'}>
                          {
                              sessionStorage.getItem('sadness').toUpperCase() === 'HIGH' ?
                                  <span id={'suggestion_text'}> Suggestion: &nbsp;
                                      <span id={'bcbs_hyperlink'}>
                                          <a href={'https://www.bluecrossma.org/myblue/your-health/mental-and-behavioral-health/mental-health-resource-center'}>
                                              Click to Visit BCBS
                                          </a>
                                      </span>
                                  </span> :
                                  <span id={'powered_by_tqi_msg'}>powered by TQIntelligence VoiceAI</span>
                          }
                        </p>
                    </div>
                </div>

                <div className={'ending_section_with_logos'}>
                    <div className={'ending_logo'}>
                        <a href={'https://www.tqintelligence.com/'}>
                            <img className={'tqi_bottom_logo'} src={tqi_logo} ></img>
                        </a>
                    </div>
                    <div className={'ending_logo'}>
                        <a href={'https://www.bluecrossma.org/myblue/your-health/mental-and-behavioral-health/mental-health-resource-center'}>
                            <img className={'bcbs_bottom_logo'} src={bcbs} ></img>
                        </a>
                    </div>
                </div>
            </div>

    )
  }
}

export default ResultsPage

