import React from "react";
import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import SurveyComponent from "./SurveyComponent";
import ACESurvey from "./ace_survey";
import Audio from "./Audio";
import ResultsPage from "./results_page";
import Testing from "./testing";
import Disclaimer from "./Disclaimer";

function App() {
  return (
    <Router>
      <Routes>
          <Route exact path="/testing" element={<Testing/>} />
          <Route exact path="/" element={<ACESurvey/>}/>
          <Route exact path="/audio" element={<Audio/>}/>
          <Route exact path="/results" element={<ResultsPage/>}/>
          <Route exact path="/disclaimer" element={<Disclaimer/>}/>
      </Routes>
    </Router>

  );
}

export default App;
