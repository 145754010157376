export const json = {
  "progressBarType": "buttons",
  "showProgressBar": "top",
   "pages": [
     {
       "name": "Q1",
       "title":"Adverse Childhood Experiences Questionnaire",
       "elements": [
         {
           "type": "boolean",
           "name": "1",
           "label": '<span style="color:#049ea1">Did a parent or other adult in the household **often** …</span>' +
               "<br />  <br />" +
               " swear at you, insult you, put you down, or humiliate you?<br />" +
               " **or**<br />" +
               " act in a way that made you afraid that you might be physically hurt? ",
           "isRequired": true,
           "correctAnswer": true,
         }]
     },

     {
       "name": "Q2",
       "title":"Adverse Childhood Experiences Questionnaire",
       "elements": [
         {
           "type": "boolean",
           "name": "2",
           "label": '<span style="color:#049ea1">Did a parent or other adult in the household **often** …</span>' +
               "<br /> <br /> " +
               " push, grab, slap, or throw something at you?<br />" +
               " **or**<br />" +
               " **ever** hit you so hard that you had marks or were injured? ",
           "isRequired": true,
           "correctAnswer": true,
         }]
     },

     {
       "name": "Q3",
       "title":"Adverse Childhood Experiences Questionnaire",
       "elements": [
         {
           "type": "boolean",
           "name": "3",
           "label": '<span style="color:#049ea1">Did an adult or person at least 5 years older than you **ever** …</span>' +
               "<br /> <br /> " +
               " touch or fondle you or have you touch their body in a sexual way? <br />" +
               " **or**<br />" +
               " try to or actually have oral, anal, or vaginal sex with you?  ",
           "isRequired": true,
           "correctAnswer": true,
         }]
     },


     {
       "name": "Q4",
       "title":"Adverse Childhood Experiences Questionnaire",
       "elements": [
         {
           "type": "boolean",
           "name": "4",
           "label":  '<span style="color:#049ea1">Did you **often** feel that …</span>' +
               "<br /> <br /> " +
               " no one in your family loved you or thought you were important or special?<br />" +
               " **or**<br />" +
               " your family didn’t look out for each other, feel close to each other, or support each other? ",
           "isRequired": true,
           "correctAnswer": true,
         }]
     },


     {
       "name": "Q5",
       "title":"Adverse Childhood Experiences Questionnaire",
       "elements": [
         {
           "type": "boolean",
           "name": "5",
           "label":  '<span style="color:#049ea1">Did you **often** feel that …</span>' +
               "<br /> <br /> " +
               " you didn’t have enough to eat, had to wear dirty clothes, and had no one to protect you??<br />" +
               " **or**<br />" +
               " your parents were too drunk or high to take care of you or take you to the doctor if you needed it? ",
           "isRequired": true,
           "correctAnswer": true,
         }]
     },



     {
       "name": "Q6",
       "title":"Adverse Childhood Experiences Questionnaire",
       "elements": [
         {
           "type": "boolean",
           "name": "6",
           "label": '<span style="color:#049ea1">Were your parents **ever** separated or divorced?</span>',
           "isRequired": true,
           "correctAnswer": true,
         }]
     },


     {
       "name": "Q7",
       "title":"Adverse Childhood Experiences Questionnaire",
       "elements": [
         {
           "type": "boolean",
           "name": "7",
           "label": '<span style="color:#049ea1">Was your mother or stepmother: </span>' +
               "<br /> <br /> "+
               "**often** pushed, grabbed, slapped, or had something thrown at her?<br />" +
               " **or**<br />" +
               "**sometimes or often** n kicked, bitten, hit with a fist, or hit with something hard?<br />" +
               " **or**<br />" +
               "**ever** repeatedly hit over at least a few minutes or threatened with a gun or knife? ",
           "isRequired": true,
           "correctAnswer": true,
         }]
     },


     {
       "name": "Q8",
       "title":"Adverse Childhood Experiences Questionnaire",
       "elements": [
         {
           "type": "boolean",
           "name": "8",
           "label": '<span style="color:#049ea1">Did you live with anyone ' +
               'who was a problem drinker or alcoholic or who used street drugs?</span>',
           "isRequired": true,
           "correctAnswer": true,
         }]
     },


     {
       "name": "Q9",
       "title":"Adverse Childhood Experiences Questionnaire",
       "elements": [
         {
           "type": "boolean",
           "name": "9",
           "label": '<span style="color:#049ea1">Was a household member ' +
               'depressed or mentally ill or did a household member attempt suicide?</span>',
           "isRequired": true,
           "correctAnswer": true,
         }]
     },


     {
       "name": "Q10",
       "title":"Adverse Childhood Experiences Questionnaire",
       "elements": [
         {
           "type": "boolean",
           "name": "10",
           "label": '<span style="color:#049ea1"> Did a household member go to prison?</span>',
           "isRequired": true,
           "correctAnswer": true,
         }]
     },

   ]
};