

import React from 'react'

import SurveyComponent from "./SurveyComponent";
import './styles.module.css'

class ACESurvey extends React.Component {
  constructor(props) {
    super(props);
  }



  render() {
    return (
      <SurveyComponent/>
    )
  }
}

export default ACESurvey